$platform-name: 'breathworks';
$assets-path: "../assets/";

// Colours
$brand-primary: #009FE3;
$brand-secondary: #55AFCA;
$brand-tertiary: #458CCD;
$brand-quarternary: #CFFFB6;
$brand-quinary: #203C63;
$brand-senary: #B4EE96;

$dark-background: #007EB4;
$dark-background-2: #5F72BE;
$light-background: #FEFEFE;
$light-background-2: #E8EDF1;
$light-background-3: #F6FBFD;
$light-background-4: #E6F8FF;

$body-colour: $brand-quinary;
$headings-colour: $brand-quinary;
$link-colour: #616cc3;

/* Fonts */
@font-face {
  font-family: "Montserrat";
  src: url($assets-path + "Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url($assets-path + "Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url($assets-path + "Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url($assets-path + "OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url($assets-path + "OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url($assets-path + "OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Typography
$font-family-base: "Open Sans", sans-serif;
$headings-font-family: "Montserrat", sans-serif;
$font-size-base: 16px;
$font-size-large: 1.25rem;
$font-size-small: 0.875rem;

$font-weight-base: 400;
$font-weight-bold: 600;
$headings-font-weight: 600;

$font-size-h1: 49px;
$font-size-h2: 39px;
$font-size-h3: 31.25px;
$font-size-h4: 25px;
$font-size-h5: 20px;
$font-size-h6: 16px;

$font-mobile-h1: 39.2px;
$font-mobile-h2: 31.2px;
$font-mobile-h3: 25px;
$font-mobile-h4: 20px;
$font-mobile-h5: 20px;
$font-mobile-h6: 16px;

// Layout
$container-grid-size: 90%;
$container-max-width: 1200px;
$post-page-max-width: 1000px;
$site-bleed: 0px;

// Logo
$logo: "logo-white.svg";
$logo-width: 150px;
$logo-height: 52px;
$logo-mobile-width: 100px;
$logo-mobile-height: 35px;

// Transitions
$transition-duration: 0.3s;
$transition-curve: ease;

// Buttons
$btn-background-colour: $brand-quarternary;
$btn-text-colour: $headings-colour;
$btn-border-radius: 500px;
$btn-border-width: 2px;
$btn-border-colour: $headings-colour;
$btn-box-shadow: none;
$btn-padding-x: 23px;
$btn-padding-y: 10px;
$btn-font-weight: 600;
$btn-font-size: $font-size-base;
$btn-letter-spacing: 0;


// Donate button
$donate-colour: $brand-quinary;
$donate-btn-colour: white;

// Menu admin
$menu-admin-enabled: false;

// Header content
$header-content-max-width: 100%;
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-content-padding-top: 0;
$header-content-padding-bottom: 0;
$header-content-padding-left: 7.5%;
$header-content-padding-right: 7.5%;

// Search
$header-search-breakpoint: 0;
$header-search-margin-right: 0;

// Search - button
$header-search-button-width: 24px;
$header-search-button-height: 24px;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: 24px;
$header-search-button-icon-colour: $brand-quarternary;

// Search when in nav
$nav-search-button-icon-colour:  $brand-quarternary;

// Navigation
$nav-breakpoint: 1130px;

// Main call to action
$cta-btn-breakpoint: 991px;

// Nav normal
$nav-normal-max-width: 100%; // $container-max-width
$nav-normal-align-items: center;
$nav-normal-mobile-background-colour: transparent;
$nav-normal-mobile-top-level-item-colour: white;
$nav-normal-mobile-chevrons-colour: white;

// Home intro
$home-intro-background-colour: transparent;
$home-intro-padding-y: 4rem;
$home-intro-margin-top: 0;
$home-intro-margin-bottom: 0;
$home-intro-content-max-width: 825px;
$home-intro-font-family: $font-family-base; // Will affect all elements within the home intro snippet
$home-intro-font-size: $font-size-base;
$home-intro-font-weight: $font-weight-base;
$home-intro-line-height: 30px;
$home-intro-colour: $body-colour;
$home-intro-mobile-padding-y: 4rem;
$home-intro-mobile-font-size: $font-size-base;

// Home features
$home-features-max-width: $container-max-width;
$home-features-background-colour: transparent;
$home-features-padding-top: 0;
$home-features-padding-bottom: 0;

// Home feeds
$home-feeds-background-colour: transparent;
$home-feeds-padding-top: 0;
$home-feeds-padding-bottom: 0;

// Event feed items
$feed-item-event-start-date-enabled: true;
$feed-item-event-location-enabled: false;

// Top level items
$nav-top-level-item-colour: $headings-colour;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-background-colour: $light-background;
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-chevrons-enabled: true;
$nav-top-level-chevrons-colour: $brand-primary;

// Submenus
$nav-submenu-item-padding: 1rem 2rem;
$nav-submenu-chevrons-enabled: true;
$nav-submenu-chevrons-colour: white;

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-breakpoint: 0;
$carousel-details-style: adjacent; // overlay, adjacent or below
$carousel-details-position-y: center; // top, center, bottom
$carousel-details-position-x: left; // left, center, right
$carousel-details-background-colour: transparent;
$carousel-details-max-width: 100%;
$carousel-details-padding: 0;
$carousel-details-border-radius: 0;
$carousel-heading-colour: $headings-colour;
$carousel-heading-font-size: $font-size-h1;
$carousel-summary-colour: $headings-colour;
$carousel-summary-font-size: $font-size-large;
$carousel-read-more-background-colour: $headings-colour;
$carousel-read-more-colour: white;
$carousel-read-more-font-size: 18px;

// Carousel controls (left-right buttons)
$carousel-controls-button-size: 44px;
$carousel-controls-button-gap: 20px;
$carousel-controls-button-background-colour: white;
$carousel-controls-button-border-radius: 50%;
$carousel-controls-button-box-shadow: 0 0 20px 0 #009FE31A;
$carousel-controls-position-y: bottom; // top, center, bottom
$carousel-controls-position-x: left; // split, left or right
$carousel-controls-icon-colour: $brand-quinary;
$carousel-controls-next-icon: "\f054"; // TODO: These are temporarily suspended
$carousel-controls-prev-icon: "\f053"; // TODO: These are temporarily suspended

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel after breakpoint
$carousel-mobile-details-background-colour: transparent;
$carousel-mobile-heading-colour: $headings-colour;
$carousel-mobile-summary-colour: $headings-colour;

// Footer
$footer-colour: $brand-quinary;
$footer-link-decoration: none;

// Footer admin links
$footer-admin-basket-link-enabled: false;
$footer-admin-login-link-enabled: false;
$footer-admin-manager-link-enabled: false;
$footer-admin-my-details-link-enabled: false;
$footer-admin-logout-link-enabled: false;

// Newsletter
$newsletter-enabled: true; // Only supported when footer-prefab $boxes-count is 1 or 2
$newsletter-background-colour: transparent;
$newsletter-colour: $headings-colour;
$newsletter-heading-text-align: center; // left, center, right
$newsletter-button-background-colour: $headings-colour;
$newsletter-button-colour: #FFFFFF;

// Card
$card-border-radius: 0;
$card-border: 0;
$card-box-shadow: none;
$card-image-background-colour: transparent;
$card-image-border-radius: 20px;
$card-details-padding: 0;
$card-details-background-colour: transparent;
$card-heading-font-family: $font-family-base;
$card-heading-colour: $headings-colour;
$card-heading-font-size: $font-size-large;
$card-summary-colour: $body-colour;
$card-footer-on: true;
$card-footer-background-colour: transparent;
$card-footer-padding: 0;

// Impact stats
$impact-stats-padding-y: 4rem;
$impact-stats-background-colour: transparent;
$impact-stats-colour: $brand-quinary;
$impact-stats-breakpoint: 0; // When do you want to break the overall layout from row to column?

// Heading
$impact-stats-heading-font-size: $font-size-h3;
$impact-stats-heading-margin-bottom: 0.5rem;

// Individual stat
$impact-stat-padding: 2rem;

// Figure
$impact-stats-figure-font-size: $font-size-h2;

// Summary
$impact-stats-summary-font-size: $font-size-base;

// Slick
$slick-enabled: true;

// Blog details
$blog-details-background-colour: transparent;
$blog-details-padding: 0;

// Sidebar
$sidebar-layout: below; // adjacent, below or above - where do you want the sidebar to be positioned on posts?

// Associated lists
$associated-latest-enabled: false;
$associated-related-enabled: true;
$associated-most-read-enabled: false;
$associated-tag-cloud-enabled: false;
$associated-max-number-of-posts: 3;
$associated-item-image-enabled: true;
$associated-list-heading-font-size: $font-size-base;
$associated-item-heading-colour: $headings-colour;
$associated-item-border: false;

// FAQs
$faq-content-max-width: 100%;
$faq-item-header-background-colour: $light-background-3;
$faq-item-header-padding: 2rem;
$faq-item-header-border-radius: 13px;
$faq-item-header-font-size: $font-size-large;
$faq-item-btn-background-colour: transparent;
$faq-item-btn-chevron-colour: $body-colour;
$faq-item-hover-btn-background-colour: transparent;
$faq-item-hover-btn-chevron-colour: white;

// Blockquote
$blockquote-max-width: 100%; // Either a px value or 100%
$blockquote-background-colour: transparent;
$blockquote-border-radius: 30px;
$blockquote-margin-y: 2rem;
$blockquote-padding: 2rem;
$blockquote-font-size: $font-size-h5;
$blockquote-line-height: 30px;
$blockquote-colour: $body-colour;
$blockquote-box-shadow: none;

// Share this page
$share-enabled: false;
$share-text-align: center; // left, center, right
$share-label-font-family: $font-family-base;
$share-label-font-size: $font-size-base;
